<template>
<div class="">
  <v-row>
    <v-col cols="2">
      <v-text-field
          filled
          label="Numéro dans la liste"
          v-model="obj.prefix"
          hide-details
      />
    </v-col>
    <v-col cols="10">
      <v-text-field
          filled
          label="Nom du produit"
          v-model="obj.title"
      />
    </v-col>
  </v-row>

    <!-- texte2-->
    <field-label class="mt-3">Texte à droite</field-label>
    <quill-editor  v-model="obj.txt1" />
    <field-label class="mt-3">Texte bas gauche</field-label>
    <quill-editor  v-model="obj.txt2" />
    <field-label class="mt-3">Texte bas droite</field-label>
    <quill-editor  v-model="obj.txt3" />

    <!--photos-->
    <list-item-images-field class="mt-3"
            :field="obj.photos"
            label="Photo(s)"
    />


</div>
</template>

<script>
import BlockMixin from "../BlockMixin";
import FieldLabel from "@/ee09/db-ui/fields/field-label.vue";
import QuillEditor from "@/ee09/db-ui/fields/quill-editor.vue";
import ListItemImagesField from "@/Models/blocks/edit/image-item-list-field.vue";

export default {
  name: "accordion-block-edit",
  components: {ListItemImagesField, QuillEditor, FieldLabel},
  mixins:[BlockMixin],
  computed:{
    /**
     *
     * @return {AccordionLocationBlock}
     */
    obj(){
      return this.block;
    },
  }
}
</script>
